import { Injectable } from '@angular/core';
import { ApiService } from './service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErpService extends ApiService {
  status = ""
  public eventslength = 0
  employeeAttendance = new BehaviorSubject('');
  disableEnterBtn = new Subject();
  employeePic = new Subject();
  employeePicClick = new Subject();
  // local
  // localpath:any = `http://localhost:8085`;
  // localpath:any=`https://erp.ithemes.xyz`


  // for production
  localpath: any = `https://erp.ithemes.xyz`


  // for testing
  // localpath:any=`https://test.erp.api.ithemes.xyz`

  //VAS
  // ApiPath:any = 'https://attendance.test.api.ithemes.xyz/api/'



  disableEnterBtnFun(value: any) {
    this.disableEnterBtn.next(value);
  }

  employeePicFun(value: any) {
    this.employeePic.next(value);
  }

  employeePicClickFun(value: any) {
    this.employeePicClick.next(value);
  }


  addEmployeeAttendance(data: any): Observable<any> {
    return this.request({
      path: `${this.localpath}/addEmployee`,
      method: 'POST',
      body: data
    });
  }

  editEmployee(data: any): Observable<any> {
    return this.request({
      path: `${this.localpath}/editEmployee`,
      method: 'PUT',
      body: data
    });
  }



  officeEmployee(employeeId: any): Observable<any> {
    return this.request({
      path: `${this.localpath}/officeEmployee/${employeeId}`,
      method: 'GET'
    });
  }



  GetCountsAdmin() {
    return this.request({
      path: `${this.localpath}/admin/getCount`,
      method: "GET",
    });
  }

  employeeThreeDays() {
    return this.request({
      path: `${this.localpath}/employeeThreeDays`,
      method: "GET"
    })
  }


  findEmployeeDetailByFilterByName(empName?: string, fromDate?: string, toDate?: string): Observable<any> {
    let queryParams = `name=${empName || ''}`;
    if (fromDate) {
      queryParams += `&fromdate=${fromDate}`;
    }
    if (toDate) {
      queryParams += `&todate=${toDate}`;
    }

    return this.request({
      path: `${this.localpath}/findEmployeeDetailByFilter?${queryParams}`,
      method: 'GET'
    });
  }

  getEmployeeById(employeeId: any): Observable<any> {
    return this.request({
      path: `${this.localpath}/employee/${employeeId}`,
      method: 'GET'
    });
  }



  GetAllRegularizeData(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/getRegularize`,
      method: "POST",
      body
    });
  }
  getPunchInAndPunchOutforAdminAllDate(data: any) {
    const { body } = data;

    return this.request({
      path: `${this.localpath}/getAllPunches`,
      method: "POST",
      body
    });
  }

  AddregularizeRequest(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/AddRegularize`,
      method: "POST",
      body
    });
  }


  GetClientVisitList(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/getClientVisitList`,
      method: "POST",
      body
    });
  }

  AddClientVisit(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addClientVisit`,
      method: "POST",
      body
    });
  }

  GetclientVisitListByEmployeeID(id: any) {
    return this.request({
      path: `${this.localpath}/getclientVisitListByEmployeeID/${id}`,
      method: "GET",
    });
  }

  AcceptRegularize(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/acceptRegularize`,
      method: "POST",
      body
    });
  }


  GetAllEmployees() {
    return this.request({
      path: `${this.localpath}/getAllUsersList`,
      method: "GET",
    });
  }

  GetAllTeamLeads() {
    return this.request({
      path: `${this.localpath}/getAllTeamLeads`,
      method: "GET",
    });
  }

  getleaveListOfEmployee(id: any) {
    return this.request({
      path: `${this.localpath}/getAllLeaveRequestById/${id}`,
      method: "GET",
    });
  }
  getAllNotificationForParticularReceiverId(id: any) {
    return this.request({
      path: `${this.localpath}/getAllNotificationForParticularReceiverId/${id}`,
      method: "GET",
    });
  }
  GetAllLeaveList() {
    return this.request({
      path: `${this.localpath}/getAllLeaveRequests`,
      method: "GET",
    });
  }
  GetAllDesignations() {
    return this.request({
      path: `${this.localpath}/getAllDesignation`,
      method: "GET",
    });
  }
  GetAllDepartments() {
    return this.request({
      path: `${this.localpath}/getAllDepartment`,
      method: "GET",
    });
  }

  GetAllEvents() {
    return this.request({
      path: `${this.localpath}/getevents`,
      method: "GET",
    });
  }


  GetNotification() {
    return this.request({
      path: `${this.localpath}/getNotification`,
      method: "GET",
    });
  }

  SendNotification() {

  }

  GetAllWorkFromListForEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getAllWFHRequestByEmployeeId`,
      method: "POST",
      body
    });
  }

  GetAllWorkFromHomeList(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getAllWFHRequests`,
      method: "POST",
      body
    });
  }
  checkLocation() {
    return this.request({
      path: `${this.localpath}/checklocation`,
      method: "GET",
    });
  }
  GetAllEmployeesDsr(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getalltask`,
      method: "POST",
      body
    });
  }

  GetOwnDsrAndUnderEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getTaskOfEmployeeAndTeamLead`,
      method: "POST",
      body
    });
  }


  GetOwnLeaveRequestAndUnderEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getAllLeaveOfEmployeeAndTeamLead`,
      method: "POST",
      body
    });
  }


  GetOwnClientVisitAndUnderEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getAllClientVisitofEmployeeandTeamLead`,
      method: "POST",
      body
    });
  }

  GetOwnWFHRequestAndUnderEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getAllWFHofEmployeeandTeamLead`,
      method: "POST",
      body
    });
  }


  GetAllEmployeesDsrForEmployee(data: any) {
    let { body } = data
    return this.request({
      path: `${this.localpath}/getalltaskofEmployee`,
      method: "POST",
      body
    });
  }
  getAllPunchinsbyids(id: any) {
    return this.request({
      path: `${this.localpath}/getallpunchinsbyid/${id}`,
      method: "GET",
    });
  }

  deleteEmployee(id: any) {
    return this.request({
      path: `${this.localpath}/deleteuser/${id}`,
      method: "DELETE",
    });
  }

  deleteDepartment(id: any) {
    return this.request({
      path: `${this.localpath}/deleteDepartment/${id}`,
      method: "DELETE",
    });
  }
  deleteDesignation(id: any) {
    return this.request({
      path: `${this.localpath}/deleteDesignation/${id}`,
      method: "DELETE",
    });
  }
  deleteEvent(id: any) {
    return this.request({
      path: `${this.localpath}/deleteeventsdetails/${id}`,
      method: "DELETE",
    });
  }
  deletewfh(id: any) {
    return this.request({
      path: `${this.localpath}/deletewfhdetails/${id}`,
      method: "DELETE",
    });
  }
  deleteDSr(id: any) {
    return this.request({
      path: `${this.localpath}/deleteuserTask/${id}`,
      method: "DELETE",
    });
  }


  deleteLeaves(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/deleteLeaveRequest/${id}`,
      method: "DELETE",
      body
    });
  }

  getLeavesDetails(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/user/getLeavesDetails`,
      method: "POST",
      body
    });
  }


  DsrNotFilledEmployeeOfTL(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/DsrNotFilledEmployeeOfTL`,
      method: "POST",
      body
    });
  }



  AskForReview(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/NotificationForRatingAndReview`,
      method: "POST",
      body
    });
  }
  terminateUser(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/terminateEmployee`,
      method: "POST",
      body
    });
  }
  ChangePassword(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/ChangePassword`,
      method: "POST",
      body
    });
  }
  getAllpaidAndUnpaid(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/getpaidandunpaidleaves`,
      method: "POST",
      body
    });
  }
  forgetpassword(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/forgot`,
      method: "POST",
      body
    });
  }
  resetpassword(data: any, id: any, token: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/reset/${id}/${token}`,
      method: "POST",
      body
    });
  }
  addPunchIn(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addPunchIn/${id}`,
      method: "POST",
      body
    });
  }
  addPunchOut(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/punchOut/${id}`,
      method: "PUT",
      body
    });
  }
  updateLeaveAllowanceStatus(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/UpdateLeaveAllowanceStatus/${id}`,
      method: "PUT",
      body
    });
  }
  updateLeaveRequestStatus(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/updateLeaveRequestStatus/${id}`,
      method: "PUT",
      body
    });
  }
  TeamLead(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/TeamLead`,
      method: "POST",
      body
    });
  }
  addEmployee(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addNewUser`,
      method: "POST",
      body
    });
  }
  getallLeaveAllowanceRequestsByEmployeeId(id: any) {
    return this.request({
      path: `${this.localpath}/getallLeaveAllowanceRequestsByEmployeeId/${id}`,
      method: "GET",
    });
  }
  getallLeaveAllowanceRequests() {
    return this.request({
      path: `${this.localpath}/getallLeaveAllowanceRequests`,
      method: "GET",
    });
  }

  getAllAttendance(data: any) {
    const { body } = data
    return this.request({
      path: `${this.localpath}/getAllAttendance`,
      method: "POST",
      body
    });
  }


  getAllAttendanceExcel(data: any) {
    const { body } = data
    return this.request({
      path: `${this.localpath}/getAllAttendanceExcel`,
      method: "POST",
      body
    });
  }


  getAllExEmployeeList() {
    return this.request({
      path: `${this.localpath}/getAllExEmployeesList`,
      method: "GET",
    });
  }

  // getAllExEmployeeList() {
  //   return this.request({
  //       path:`${this.localpath}/Exemployee`,
  //       method:"GET",
  //     });
  //   }
  getPunchInAndPunchOutByEmployeeId(id: any) {
    return this.request({
      path: `${this.localpath}/getPunchInAndPunchOutByEmployeeId/${id}`,
      method: "GET",
    });
  }
  userlocation(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/post/${id}`,
      method: "POST",
      body
    });
  }
  addDsr(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addTask`,
      method: "POST",
      body
    });
  }
  addleaveallowance(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/AddLeaveAllowance`,
      method: "POST",
      body
    });
  }
  addleaveRequest(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addLeaveRequest`,
      method: "POST",
      body
    });
  }
  addwfh(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/WFHRequest`,
      method: "POST",
      body
    });
  }
  addDepartment(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addDepartment`,
      method: "POST",
      body
    });
  }
  addDesignation(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addDesignation`,
      method: "POST",
      body
    });
  }
  addEvent(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addevent`,
      method: "POST",
      body
    });
  }
  getNoofLeaveForEmployee(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllEmpLeaveRequestByEmpID/${id}`,
      method: "GET",
      body
    });
  }
  getLeaveRequestByEmployeeData(id: any, objid: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getLeaveRequestByEmployeeData/${id}/${objid}`,
      method: "GET",
      body
    });
  }
  Editwfh(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllWFHRequestById/${id}`,
      method: "GET",
      body
    });
  }
  EditEvent(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllEventsbyid/${id}`,
      method: "GET",
      body
    });
  }
  EditDepartment(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllDepartmentById/${id}`,
      method: "GET",
      body
    });
  }
  EditDesignation(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllDesignationById/${id}`,
      method: "GET",
      body
    });
  }
  getLateEmployeeList() {
    return this.request({
      path: `${this.localpath}/getConsistentlyLateEmployees`,
      method: "GET",
    });
  }
  getEmployeesByPunctualityStatus(data: any) {

    return this.request({
      path: `${this.localpath}/getEmployeesByPunctualityStatus?status=${data}`,
      method: "GET",
    });
  }
  getLateEmployeesListOfToday() {
    return this.request({
      path: `${this.localpath}/getLateEmployeesListOfToday`,
      method: "GET",
    });
  }
  getallLeaveEmployee() {
    return this.request({
      path: `${this.localpath}/getallLeaveEmployee`,
      method: "GET",
    });
  }
  EditDsr(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllTaskListById/${id}`,
      method: "GET",
      body
    });
  }
  EditEmployee(id: any) {
    const { body } = id;
    return this.request({
      path: `${this.localpath}/getAllUsersListById/${id}`,
      method: "GET",
      body
    });
  }
  UpdateEmployee(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateUser/${id}`,
      method: "PUT", // Use PUT method
      body: data // Pass the data as the body of the request
    });
  }
  UpdateDsr(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateTask/${id}`,
      method: "PUT",
      body: data
    });
  }
  UpdateDepartment(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateDepartment/${id}`,
      method: "PUT",
      body: data
    });
  }
  UpdateDesignation(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateDesignation/${id}`,
      method: "PUT",
      body: data
    });
  }
  UpdateEvent(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateeventsdetails/${id}`,
      method: "PUT",
      body: data
    });
  }
  updateWFHStatus(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateWFHStatus/${id}`,
      method: "PUT",
      body: data
    });
  }
  Updatewfh(id: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateWFHRequest/${id}`,
      method: "PUT",
      body: data
    });
  }
  updateLeaveRequest(id: any, monthdate: any, data: any) {
    return this.request({
      path: `${this.localpath}/updateLeaveRequest/${id}/${monthdate}`,
      method: "PUT",
      body: data
    });
  }



  employeeAttendanceFun(value: any) {
    this.employeeAttendance.next(value);
  }


  download(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  addPunchInWithEntrySystem(data: any, id: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/addPunchInWithEntrySystem`,
      method: "POST",
      body
    });
  }

  PunchOutWithEntrySystem(data: any) {
    const { body } = data;
    return this.request({
      path: `${this.localpath}/punchOutWithEntrySystem`,
      method: "PUT",
      body
    });
  }

}
